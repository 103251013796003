*{
  margin: 0;
}


.Container{
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #5652A3;
  .MainContainer{
    border-radius: 2rem;
    height: fit-content;
    position: relative;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    padding: 0 !important;
    background-color: #f2f2f2 !important;
    .TopContainer{
      display: flex;
      padding: 1rem;
      gap: 1rem;
      align-items: center;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      img{
        width: 10%;
      }
      h5{
        font-weight: bolder;
        color: #5652A3;
      }
    }
    .MessagesContainer{
      height: 70vh;
      width: 100%;
      overflow-y: scroll ;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      transition: all .4s;
      position: relative;
    }
    .InputContainer{
      bottom: 0;
      padding: 1rem;
      background: rgba(255,255,255,.05);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      .TextField{
        fieldset{
          outline: none !important;
          border: none !important;
        }
        textarea{
          background: transparent;
          outline: none;
          border: none ;
        }
      }
      
      .SendIcon{
        color: white;
        background-color: #5652A3;
        &.offline{
          background-color: grey;
        }
      }
    }
  }
}
